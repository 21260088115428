import {
  dateToAMD,
  dateToMDA,
  isMonday,
  isSunday,
  SERVER_URL,
} from "../helpers/general";
import axios from "axios";
import JSZip from "jszip";

import { useEffect, useReducer, useState } from "react";
import MyButton from "./MyButton";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, TextField } from "@mui/material";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import DatePick from "./DatePick";
import { InputReducer } from "../reducers/InputReducer";
import { generatePDF } from "../helpers/pdfGen";
import { downloadAttendancesPDF } from "../helpers/attendancesPDF";
import { saveAs } from "file-saver";
import dayjs from "dayjs";

const FullInvoice = () => {
  const zip = new JSZip();

  const [invoiceType, setInvoiceType] = useState("week");
  const [projectCheckboxes, setProjectCheckboxes] = useState({});
  const [blobsArray, setBlobsArray] = useState([]);
  const [shouldSend, setShouldSend] = useState(false);
  const facturaReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_DATA":
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [facturaData, facturaDispatch] = useReducer(facturaReducer, {
    data: null,
  });
  const [viewBeforeSend, setViewBeforeSend] = useState(false);
  const [viewFacturaData, setViewFacturaData] = useState(null);

  const [range, setRange] = useState({ date_start: null, date_end: null });
  const [sending, setSending] = useState(false);
  const [projects, setProjects] = useState([]);
  const { getData, postData } = useQuery();

  let projectsToInvoice = [];
  let projectTitles = [];
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const handleInvoiceType = (e) => {
    setInvoiceType(e.target.value);
  };
  const setStart = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_start: newDate });
  };

  const setEnd = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_end: newDate });
  };
  const setProjectsArray = (e) => {
    if (e.target.checked) {
      projectsToInvoice.push(e.target.id);
    } else {
      const index = projectsToInvoice.indexOf(e.target.id);
      if (index !== -1) {
        projectsToInvoice.splice(index, 1);
      }
    }
  };
  const getProjectsToCheck = async () => {
    setProjects([]);
    setError({ error: false, severity: "", message: "" });

    let endpoint;
    if (invoiceType === "day") {
      if (!range.date_start) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date",
        });
        return;
      }
      endpoint = `invoiceDailyprojects/${range.date_start}`;
    } else {
      endpoint = `invoiceWeeklyprojects/${range.date_start}/${range.date_end}`;
      if (!range.date_start || !range.date_end) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date range",
        });
        return;
      }
      const mondayTrue = isMonday(range.date_start);
      const sundayTrue = isSunday(range.date_end);
      if (!mondayTrue || !sundayTrue) {
        setError({
          status: true,
          message:
            "Start date must be a monday and end date must be a sunday, please check",
          severity: "error",
        });
        return;
      }
    }

    setProjects([]);

    const projects = await getData(endpoint, localStorage.token);
    if (projects.data.status === 201) {
      setError({
        status: true,
        message: "There's no projects to invoice",
        severity: "error",
      });
      setSending(false);

      return;
    }
    setProjects(projects.data.data);
    // return FullAttendances;
  };

  const getInvoices = async () => {
    let body;
    let endpoint;
    projectsToInvoice = Object.keys(projectCheckboxes).filter(
      (clave) => projectCheckboxes[clave]
    );
    setProjectCheckboxes({});
    if (invoiceType === "day") {
      endpoint = "invoiceDaily";
      body = {
        date_invoice: range.date_start,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    } else {
      endpoint = "invoiceWeekly";
      body = {
        date_start: range.date_start,
        date_end: range.date_end,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    }

    const invoicesToPrint = await postData(endpoint, body, localStorage.token);
    facturaDispatch({
      type: "CHANGE_DATA",
      payload: invoicesToPrint.data.data.data,
    });
  };
  const mapProjectsToPDF = async (fact) => {
    const mainArray = Object.values(facturaData.data)[0];
    projectTitles = Object.keys(mainArray.projects);
    if (!mainArray.projects || !mainArray.attendances) {
      setError({
        status: true,
        message: "Internal error, contact support for assistance",
        severity: "error",
      });
      return
    }
    const projects = Object.values(mainArray.projects);
    const attendancesData = Object.values(mainArray.attendances);

    setViewFacturaData([
      Object.keys(mainArray.projects),
      Object.values(mainArray.projects),
    ]);

    const hoursRate = await getData("getData", localStorage.token);
    const rates = {
      st: hoursRate.data.data.billingRates[0].regular_hourly_rate,
      st_ot: hoursRate.data.data.billingRates[0].overtime_hourly_rate,
      gl: hoursRate.data.data.billingRates[1].regular_hourly_rate,
      gl_ot: hoursRate.data.data.billingRates[1].overtime_hourly_rate,
    };
    projects.map((project, index) => {
      generatePDF(
        project,
        index,
        invoiceType,
        projectTitles,
        range,
        facturaData,
        setProjects,
        blobsArray,
        setBlobsArray,
        rates
      );
      const attendancesReport = [
        {
          data: Object.values(attendancesData[index]),
        },
      ];
      downloadAttendancesPDF(
        attendancesReport,
        range,
        index,
        projectTitles,
        invoiceType,
        setBlobsArray
      );

      if (index === projects.length - 1) {
        setViewBeforeSend(true);
        // zipFiles();
        // setShouldSend(true);
      }
    });
  };

  useEffect(() => {
    if (blobsArray.length > 0) {
      zipFiles();
    }
  }, [blobsArray]);

  const zipFiles = () => {
    const zip = new JSZip();
    const today = dayjs().format("MM-DD-YYYY");
    blobsArray.forEach((blob, index) => {
      const projectFolder = zip.folder(`${blob.project_number}`);
      projectFolder.file(
        `${blob.project_number}-Invoice-${blob.invoice_number}-from-${blob.start_date}-to-${blob.end_date}.pdf`,
        blob.invoice
      );
      projectFolder.file(
        `${blob.project_number}-attendances-from-${blob.start_date}-to-${blob.end_date}.pdf`,
        blob.attendance
      );
    });
    zip.generateAsync({ type: "blob" }).then((zipBlob) => {
      saveAs(zipBlob, `Projects_${today}.zip`);
    });
  };

  useEffect(() => {
    if (shouldSend) {
      sendProjectsToServer();
      setShouldSend(false);
    }
  }, [shouldSend]);

  const selectAllProjects = (e, projectList) => {
    const newProjectCheckboxes = {};
    for (const project of projectList) {
      const projectId = project.id || project.project_number;
      newProjectCheckboxes[projectId] = e.target.checked;
    }
    setProjectCheckboxes(newProjectCheckboxes);
  };

  const filterProjectsToEmail = (projects) => {
    return projects.filter((item) => item.project_number in projectCheckboxes);
  };
  const sendProjectsToServer = async () => {
    if (blobsArray.length === 0) {
      return;
    }
    const arrayToSend = filterProjectsToEmail(blobsArray);

    setBlobsArray([]);

    try {
      const formData = new FormData();
      arrayToSend.forEach((item, index) => {
        // Agregar el blob (factura)
        formData.append(`invoices[${index}][invoice]`, item.invoice);
        formData.append(`invoices[${index}][attendance]`, item.attendance);

        // Agregar los otros datos (proyecto, total, fecha)
        formData.append(
          `invoices[${index}][invoice_number]`,
          item.invoice_number
        );
        formData.append(`invoices[${index}][project]`, item.project);
        formData.append(
          `invoices[${index}][project_number]`,
          item.project_number
        );
        formData.append(`invoices[${index}][total]`, item.total);
        formData.append(`invoices[${index}][start_date]`, item.start_date);
        formData.append(`invoices[${index}][end_date]`, item.end_date);
      });

      // Configurar el envío
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`, // Usar getItem para acceder al token
        },
      };
      // Enviar la solicitud con Axios utilizando async/await
      const response = await axios.post(
        `${SERVER_URL}/uploadInvoiceAttendance`,
        formData,
        config
      );
      setError({
        status: true,
        message: "Email sent successfully,  you'll be redirected",
        severity: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error al enviar los PDFs:", error);
      setError({
        status: true,
        message: "Failed to send the email",
        severity: "error",
      });
      setSending(false);
    }
  };

  useEffect(() => {
    if (facturaData.data) {
      mapProjectsToPDF();
    }
  }, [facturaData.data]);

  return (
    <>
      <Navbar />
      <TopBar />
      <section className="report create shape" style={{ gridRow: "2/4" }}>
        {!viewBeforeSend ? (
          <>
            <section className="content content_report">
              <h2>Invoices</h2>

              <DatePick
                setStart={setStart}
                setEnd={setEnd}
                day={invoiceType === "day"}
              />
              {!sending ? (
                <MyButton
                  text="Get Projects"
                  className="add mb-10"
                  onClick={() => {
                    getProjectsToCheck();
                  }}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ alignSelf: "center" }}
                />
              )}

              <MyButton text="Go Back" className="logout" onClick={() => {}} />
              <section className="selectors" style={{ marginTop: "2rem" }}>
                <div className="check">
                  <input
                    type="radio"
                    name="invoiceType"
                    value="week"
                    checked={invoiceType === "week"}
                    onChange={handleInvoiceType}
                  />
                  <label> Weekly Invoice</label>
                </div>
                <div className="check">
                  <input
                    type="radio"
                    name="invoiceType"
                    value="day"
                    onChange={handleInvoiceType}
                    checked={invoiceType === "day"}
                  />

                  <label> Daily Invoice</label>
                </div>
              </section>
            </section>
            <section className="pt_invoice">
              {projects.length > 0 && (
                <>
                  <h2>Type the starting invoice number</h2>
                  <TextField
                    className="input_form"
                    id="invoice_number"
                    label="Ex. 1000"
                    variant="outlined"
                    value={invoiceNumber}
                    onChange={(e) => {
                      setInvoiceNumber(e.target.value);
                    }}
                  />
                  <h2>Select projects to invoice</h2>
                  <div className="check_project shape">
                    <input
                      type="checkbox"
                      id="select_all"
                      onChange={(e) => selectAllProjects(e, projects)}
                    />
                    <h5 className="title">"Select All"</h5>
                  </div>
                  <div className="check_container">
                    {projects.map((proyecto) => {
                      return (
                        <div key={proyecto.id} className="check_project shape">
                          <input
                            type="checkbox"
                            checked={projectCheckboxes[proyecto.id] || false}
                            id={proyecto.id}
                            onChange={(e) => {
                              setProjectsArray(e);
                              setProjectCheckboxes((prevCheckboxes) => ({
                                ...prevCheckboxes,
                                [proyecto.id]: e.target.checked,
                              }));
                            }}
                          />
                          <h5 className="title">{proyecto.project_name}</h5>
                        </div>
                      );
                    })}
                  </div>

                  {!sending ? (
                    <MyButton
                      text="Get Invoice"
                      className={`add mb-10`}
                      onClick={() => {
                        setError({
                          status: false,
                          message: "",
                          severity: "",
                        });
                        if (
                          projectsToInvoice.length > 0 ||
                          invoiceNumber !== ""
                        ) {
                          getInvoices();
                        } else {
                          setError({
                            status: true,
                            message:
                              "You must type the invoice number and select at least one project",
                            severity: "error",
                          });
                          return;
                        }
                      }}
                    />
                  ) : (
                    <CircularProgress
                      color="primary"
                      sx={{ alignSelf: "center" }}
                    />
                  )}
                </>
              )}
            </section>
          </>
        ) : (
          <section>
            {viewFacturaData &&
              viewFacturaData[1].map((project, index) => {
                const projectKey = viewFacturaData[0][index].split("+")[1]; // Extraer el ID del proyecto
                const projectName = viewFacturaData[0][index].split("+")[0]; // Extraer el nombre del proyecto
                const isChecked = projectCheckboxes[projectKey] || false; // Obtener el estado del checkbox

                return (
                  <div
                    key={index}
                    className="check_project check_project--amount shape shape"
                  >
                    <input
                      type="checkbox"
                      checked={isChecked}
                      id={projectKey}
                      onChange={(e) => {
                        setProjectsArray(e);
                        setProjectCheckboxes((prevCheckboxes) => ({
                          ...prevCheckboxes,
                          [projectKey]: e.target.checked,
                        }));
                      }}
                    />
                    <h5 className="title">{projectName}</h5>
                    <h3>Total: ${project.TOTAL.grandTotal}</h3>
                  </div>
                );
              })}

            {!sending ? (
              <>
                <div className="check_project shape">
                  <input
                    type="checkbox"
                    id="select_all"
                    onChange={(e) => selectAllProjects(e, blobsArray)}
                  />
                  <h5 className="title">"Select All"</h5>
                </div>
                <MyButton
                  text="Send Email"
                  className={`add mt-10`}
                  onClick={() => {
                    setError({
                      status: false,
                      message: "",
                      severity: "",
                    });
                    if (
                      Object.values(projectCheckboxes).every(
                        (value) => value === false
                      )
                    ) {
                      setError({
                        status: true,
                        message: "You must select at least one project",
                        severity: "error",
                      });
                    } else {
                      setSending(true);
                      setError({
                        status: true,
                        message: "Sending email, please wait a moment",
                        severity: "info",
                      });
                      setShouldSend(() => true);
                    }
                  }}
                />
              </>
            ) : (
              <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
            )}
          </section>
        )}
        {error.status && (
          <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
            {error.message}
          </Alert>
        )}
      </section>
    </>
  );
};

export default FullInvoice;
