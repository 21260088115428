import {
  dateToAMD,
  dateToMDA,
  getDate,
  hhmm,
  limitChars,
} from "../helpers/general";
import { useEffect, useState } from "react";
import MyButton from "./MyButton";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import DatePick from "./DatePick";
import jsPDF from "jspdf";
import { downloadAttendancesPDF } from "../helpers/attendancesPDF";

const ReportByDate = (props) => {
  const navigate = useNavigate();
  const { projectId, setReportByDate, type } = props;
  const { getData, postData } = useQuery();
  const [sending, setSending] = useState(false);
  const [attendancesReport, setAttendancesReport] = useState([]);
  const [range, setRange] = useState({ date_start: null, date_end: null });
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });
  const setStart = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_start: newDate });
  };
  const setEnd = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_end: newDate });
  };

  const getAttendances = async (body) => {
    let endpoint;
    if (type === "full") {
      endpoint = "unifiedAttendance";
    }
    if (type === "PDF") {
      endpoint = "employeeAttendanceProject";
    }
    if (type === "byProject") {
      endpoint = "employeeAttendanceDate";
    }

    const attendances = await postData(endpoint, body, localStorage.token);
    if (attendances.data.status === 201) {
      return { status: "error", message: attendances.data.data.message };
    }
    setSending(false);
    return attendances;
  };

  const downloadExcel = () => {
    if (attendancesReport.length === 0) {
      return;
    }
    const rows = attendancesReport.map((attendance) => ({
      date: dateToAMD(attendance.attendance_date),
      project_number: attendance.project_number,
      project_name: attendance.project_name,
      name: attendance.name_employee,
      code: attendance.cod,
      start: attendance.start,
      end: attendance.end,
      breaks: attendance.break_time,
      total: attendance.total_time,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, `Attendances`);

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Date",
        "Project Number",
        "Project Name",
        "Name",
        "Code",
        "Start Time",
        "End Time",
        "Breaks",
        "Total",
      ],
    ]);
    if (type === "byProject") {
      XLSX.writeFile(
        workbook,
        `Project_${attendancesReport[0].project_name}_${range.date_start}_to_${range.date_end}.xlsx`,
        {
          compression: true,
        }
      );
    }
    if (type === "full") {
      XLSX.writeFile(workbook, `Projects_Report.xlsx`, {
        compression: true,
      });
    }
  };



  const getReport = async () => {
    setError({
      status: false,
      message: "",
      severity: "",
    });
    if (!range.date_start || !range.date_end) {
      setError({
        status: true,
        message: "You must select a valid range of days",
        severity: "error",
      });
      return;
    }

    setSending(true);
    const body = {
      date_start: range.date_start,
      date_end: range.date_end,
      id: projectId,
    };
    const data = await getAttendances(body);
    if(data.data?.status===200 && data.data?.data?.data?.data?.length<1){
      setSending(false);
      setError({
        status: true,
        message: "There are no projects with assistances for this dates",
        severity: "error",
      });
    }
    if (data.status==="error") {
      setSending(false);
      setError({
        status: true,
        message: data.message,
        severity: "error",
      });
      return;
    }
    let asistencias;
    type === "PDF"
      ? (asistencias = data.data.data.data.data)
      : (asistencias = data.data.data);
    if (data.data.status === 201) {
      setError({
        status: true,
        message: asistencias.message,
        severity: "error",
      });
      setSending(false);
      return;
    }
    if (asistencias.length > 0) {
      setAttendancesReport(asistencias);
      return;
    }
  };
  useEffect(() => {
    setSending(false);
    if (type === "PDF") {
      downloadAttendancesPDF(attendancesReport,range);
      return;
    }
    downloadExcel();
  }, [attendancesReport]);

  return (
    <section className="report create shape">
      <>
        <DatePick setStart={setStart} setEnd={setEnd} />
      </>

      {!sending ? (
        <MyButton text="Get Report" className="add mb-10" onClick={getReport} />
      ) : (
        <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
      )}
      <MyButton
        text="Go Back"
        className="logout"
        onClick={() => {
          if (type === "byProject") {
            setReportByDate(false);
          } else {
            navigate(-1);
          }
        }}
      />

      {error.status && (
        <Alert
          //  className="project_alert"
          severity={error.severity}
          sx={{ marginTop: "1rem" }}
        >
          {error.message}
        </Alert>
      )}
    </section>
  );
};

export default ReportByDate;
