import { useEffect, useReducer, useState } from "react";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, Skeleton, TextField } from "@mui/material";

import { InputReducer } from "../reducers/InputReducer";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";

const Settings = () => {
  const [inputData, inputDispatch] = useReducer(InputReducer, {
    email: "",
    st: "",
    st_ot: "",
    gl: "",
    gl_ot: "",
  });
  const { getData, postData } = useQuery();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const inputs = [
    {
      name: "email",
      label: "Email",
    },
    {
      name: "st",
      label: "ST Rate",
    },
    {
      name: "st_ot",
      label: "ST OverTime Rate",
    },
    {
      name: "gl",
      label: "GL Rate",
    },
    {
      name: "gl_ot",
      label: "GL OverTime Rate",
    },
  ];

  const sendData = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Validate email
    if (!emailRegex.test(inputData.email) || inputData.email.trim() === "") {
      setError({
        status: true,
        message: "Please provide a valid email address.",
        severity: "error",
      });
      setSending(false);
      return;
    }   
  
    const updatedData = {
      email: inputData.email,
      ratesToUpdate: [
        {
          regular_hourly_rate: inputData.st,
          overtime_hourly_rate: inputData.st_ot,
          id_cod: 1,
        },
        {
          regular_hourly_rate: inputData.gl,
          overtime_hourly_rate: inputData.gl_ot,
          id_cod: 2,
        },
      ],
      companyId: 1,
    };
  
    try {
      const send = await postData("updateData", updatedData, localStorage.token);
      if (send.data.status === 200) {
        setError({
          status: true,
          message: "Data updated successfully",
          severity: "success",
        });
        setSending(false);
      } else {
        setError({
          status: true,
          message: "An error occurred while updating data.",
          severity: "error",
        });
      }
    } catch (error) {
      setError({
        status: true,
        message: "Network or server error occurred.",
        severity: "error",
      });
    }
  };
  
  const getInitialData = async () => {
    const data = await getData("getData", localStorage.token);
    const info = data.data.data;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "email",
      fieldValue: info.companyEmail,
    });
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "st",
      fieldValue: info.billingRates[0].regular_hourly_rate,
    });
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "st_ot",
      fieldValue: info.billingRates[0].overtime_hourly_rate,
    });
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "gl",
      fieldValue: info.billingRates[1].regular_hourly_rate,
    });
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "gl_ot",
      fieldValue: info.billingRates[1].overtime_hourly_rate,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getInitialData();
  }, []);
  return (
    <>
      <Navbar />
      <TopBar />
      <section className="report create shape" style={{ gridRow: "2/4" }}>
        <section className="content settings">
          <h2>Settings</h2>
          <p>This is a danger zone, be careful</p>
          {!loading ? (
            <>
              {inputs.map((input) => {
                return (
                  <TextField
                    id={input.name}
                    name={input.name}
                    label={input.label}
                    value={inputData[input.name] || ""}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="input_form"
                  />
                );
              })}

              {error.severity !== "warning" && !sending && (
                <MyButton
                  text="Set Values"
                  onClick={() =>
                    setError({
                      status: true,
                      message: "Are you sure you want to update the values?",
                      severity: "warning",
                    })
                  }
                  className="action"
                />
              )}
            </>
          ) : (
            <>
              {inputs.map((input) => {
                return (
                  <Skeleton
                    variant="rectangular"
                    className="mb-10"
                    height={40}
                  />
                );
              })}
            </>
          )}
        </section>
        {error.status && (
          <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
            {error.message}
          </Alert>
        )}
        {error.severity === "warning" && !sending && (
          <MyButton
            text="Yes"
            onClick={() => {
              setSending(true);
              setError({
                status: true,
                message: "Sending data",
                severity: "info",
              });
              sendData();
            }}
            className="add mt-10"
          />
        )}
        {sending && <CircularProgress />}
      </section>
    </>
  );
};

export default Settings;
