import jsPDF from "jspdf";
import { dateToMDA, hhmm, dateToAMD } from "./general";

export const downloadAttendancesPDF = (
  attendancesReport,
  range,
  index,
  projectTitles,
  invoiceType,
  setBlobsArray
) => {
  if (attendancesReport.length === 0) {
    return;
  }
  const projectTitleData = projectTitles[index].split("+");

  let projectDataArray;

  if (invoiceType === "week") {
    projectDataArray = [
      projectTitleData[0],
      projectTitleData[1],
      `${dateToMDA(range.date_start)} to ${dateToMDA(range.date_end)}`,
    ];
  } else {
    projectDataArray = [
      projectTitleData[0],
      projectTitleData[1],
      `${dateToMDA(range.date_start)}`,
    ];
  }

  attendancesReport.map((report) => {
    const pdf = new jsPDF("p", "mm", "a4", true);
    // Configuración del tamaño de texto y colores y ancho
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const sideMargin = 10;
    pdf.setFontSize(12);
    const orangeColor = [248, 112, 53];
    const whiteColor = [255, 255, 255];
    const blackColor = [0, 0, 0];
    const cellHeight = 8;
    const logoImg = new Image();
    logoImg.src = "../assets/logo.png";
    const logoWidth = 40;
    const logoHeight = 18;
    pdf.addImage(logoImg, "PNG", sideMargin, 5, logoWidth, logoHeight);

    pdf.setTextColor(...blackColor);
    pdf.setFontSize(8);
    pdf.text(
      `Project: ${projectTitleData[0]} # ${projectTitleData[1]}`,
      pageWidth - sideMargin,
      5 + logoHeight / 2,
      {
        align: "right",
      }
    );
    pdf.text(
      `Dates: ${dateToMDA(range.date_start)} to ${dateToMDA(range.date_end)}`,
      pageWidth - sideMargin,
      5 + logoHeight / 2 + cellHeight,
      {
        align: "right",
      }
    );

    // CABECERA
    pdf.setFillColor(...orangeColor);
    pdf.rect(
      sideMargin,
      logoHeight + 10,
      pageWidth - sideMargin * 2,
      cellHeight,
      "F"
    );
    const marginNumber = pageWidth / 4;
    const marginName = marginNumber + 20;

    pdf.setTextColor(...whiteColor);

    const columns = 8;

    const columnWidth = (pageWidth - sideMargin * 2) / columns;
    pdf.text("Employee Name", sideMargin + 5, logoHeight + 11 + cellHeight / 2);

    pdf.text(
      "Type",
      sideMargin + 10 + columnWidth,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "Start Time",
      sideMargin + 5 + columnWidth * 2,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "End Time",
      sideMargin + 5 + columnWidth * 3,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "Breaks",
      sideMargin + 5 + columnWidth * 4,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "Total Time",
      sideMargin + 5 + columnWidth * 5,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "Signature",
      sideMargin + 5 + columnWidth * 6,
      logoHeight + 11 + cellHeight / 2
    );
    pdf.text(
      "Date",
      sideMargin + 5 + columnWidth * 7,
      logoHeight + 11 + cellHeight / 2
    );

    let yPosition = logoHeight + 25;
    pdf.setTextColor(...blackColor);
    pdf.setFillColor(...whiteColor);
    pdf.setFontSize(8);

    let currentPage = 1;
    let printingItem = 1;
    const dataToPrint = report.data;
    dataToPrint.map((singleData) => {
      let startTime = hhmm(singleData.start);
      let endTime = hhmm(singleData.end);
      let breakTime = hhmm(singleData.break_time);
      let totalTime = singleData.total_time.toString();
      let signature = `../${singleData.signature_employee}`;
      if (currentPage === 1 && printingItem > 30) {
        currentPage++;
        printingItem = 1;
        yPosition = 18;
        pdf.addPage();
      } else if (currentPage > 1 && printingItem > 34) {
        currentPage++;
        printingItem = 1;
        pdf.addPage();
      }
      pdf.text(
        singleData.name_employee,
        sideMargin + 5,
        yPosition + (printingItem - 1) * cellHeight
      );
      pdf.text(
        singleData.cod,
        sideMargin + 10 + columnWidth,
        yPosition + (printingItem - 1) * cellHeight
      );
      pdf.text(
        startTime,
        sideMargin + 5 + columnWidth * 2,
        yPosition + (printingItem - 1) * cellHeight
      );
      pdf.text(
        endTime,
        sideMargin + 5 + columnWidth * 3,
        yPosition + (printingItem - 1) * cellHeight
      );
      pdf.text(
        breakTime,
        sideMargin + 5 + columnWidth * 4,
        yPosition + (printingItem - 1) * cellHeight
      );
      pdf.text(
        totalTime,
        sideMargin + 10 + columnWidth * 5,
        yPosition + (printingItem - 1) * cellHeight
      );

      /*    let image = new Image();
        image.src = signature;
        pdf.addImage(
          image,
          "PNG",
          sideMargin + 5 + columnWidth * 6,
          yPosition + (printingItem - 1) * cellHeight - cellHeight / 2,
          10,
          5
        );
 */
      /*  pdf.text(
          breakTime,
          marginName + 80,
          yPosition + (printingItem - 1) * cellHeight
        ); */
      pdf.text(
        dateToMDA(dateToAMD(singleData.attendance_date)),
        sideMargin + 5 + columnWidth * 7,
        yPosition + (printingItem - 1) * cellHeight
      );
      printingItem++;
    });
    // Guardar el PDF Localmente

   /*  pdf.save(
      `${projectTitleData[1]}-attendances-from-${dateToMDA(
        range.date_start
      )}-to-${dateToMDA(range.date_end)}.pdf`
    ); */
    const blob = pdf.output("blob");

    //pasar blob al array de objetos

    setBlobsArray((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], attendance: blob };
      return newData;
    });
    // Crea una URL para el blob
    //const url = URL.createObjectURL(blob);

    // Abre una nueva ventana y muestra la vista previa del PDF
    //  window.open(url, "_blank");
  });
};
