import jsPDF from "jspdf";
import { doData, doHeader, drawRectangle, writeText } from "./invoicesFunction";
import { dateToMDA, getDate } from "./general";
import { footerData, totalTitles } from "./testData";

let testData = [
  {
    GL: {
      entries: {
        2522: {
          name_employee: "Jose Luis Petit",
          total_time: 93,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 53,
          regulartime: 40,
        },
        2525: {
          name_employee: "Ruben Nova",
          total_time: 10,
          attendance_date: "2024-09-24T03:00:00.000Z",
          overtime: 1,
          regulartime: 10,
        },
        2529: {
          name_employee: "Ilmer Rivero",
          total_time: 23.5,
          attendance_date: "2024-09-24T03:00:00.000Z",
          overtime: 4,
          regulartime: 23.5,
        },
        2530: {
          name_employee: "Jhony Ledezma",
          total_time: 65,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 25,
          regulartime: 40,
        },
        2532: {
          name_employee: "Isac Nova",
          total_time: 82.5,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 42.5,
          regulartime: 40,
        },
        2544: {
          name_employee: "Celzo Vargas",
          total_time: 8,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 20,
          regulartime: 8,
        },
        2557: {
          name_employee: "Carlos Hernandez",
          total_time: 90.5,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 50.5,
          regulartime: 40,
        },
        2560: {
          name_employee: "Bryan Tijerino",
          total_time: 27.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 1,
          regulartime: 27.5,
        },
        2561: {
          name_employee: "Felipe Tijerino",
          total_time: 13.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 1,
          regulartime: 13.5,
        },
        2564: {
          name_employee: "Ronal Aragon",
          total_time: 13.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 2,
          regulartime: 13.5,
        },
        2568: {
          name_employee: "Antonio Blanco",
          total_time: 12.5,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 12.5,
        },
        2568: {
          name_employee: "Antonio Blanco",
          total_time: 12.5,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 4,
          regulartime: 12.5,
        },
        2569: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 6,
          regulartime: 24,
        },
        2570: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 6,
          regulartime: 24,
        },
        2571: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 24,
        },
        2572: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 2,
          regulartime: 24,
        },
        2573: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2574: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2575: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2576: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2577: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2578: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2579: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2580: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2581: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2582: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2583: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2584: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2585: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2586: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2587: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2588: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2589: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2590: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2591: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2592: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2593: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2594: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2595: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2596: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2597: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
      },
      hours: 286.5,
      hours_amount: 7162.5,
      hours_overtime: 55.5,
      hours_overtime_amount: 2081.25,
    },
    ST: {
      entries: {
        2522: {
          name_employee: "Jose Luis Petit",
          total_time: 93,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 53,
          regulartime: 40,
        },
        2525: {
          name_employee: "Ruben Nova",
          total_time: 10,
          attendance_date: "2024-09-24T03:00:00.000Z",
          overtime: 1,
          regulartime: 10,
        },
        2529: {
          name_employee: "Ilmer Rivero",
          total_time: 23.5,
          attendance_date: "2024-09-24T03:00:00.000Z",
          overtime: 1,
          regulartime: 23.5,
        },
        2530: {
          name_employee: "Jhony Ledezma",
          total_time: 65,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 25,
          regulartime: 40,
        },
        2532: {
          name_employee: "Isac Nova",
          total_time: 82.5,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 42.5,
          regulartime: 40,
        },
        2544: {
          name_employee: "Celzo Vargas",
          total_time: 8,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 1,
          regulartime: 8,
        },
        2557: {
          name_employee: "Carlos Hernandez",
          total_time: 90.5,
          attendance_date: "2024-09-23T03:00:00.000Z",
          overtime: 50.5,
          regulartime: 40,
        },
        2560: {
          name_employee: "Bryan Tijerino",
          total_time: 27.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 2,
          regulartime: 27.5,
        },
        2561: {
          name_employee: "Felipe Tijerino",
          total_time: 13.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 1,
          regulartime: 13.5,
        },
        2564: {
          name_employee: "Ronal Aragon",
          total_time: 13.5,
          attendance_date: "2024-09-25T03:00:00.000Z",
          overtime: 1,
          regulartime: 13.5,
        },
        2568: {
          name_employee: "Antonio Blanco",
          total_time: 12.5,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 12.5,
        },
        2568: {
          name_employee: "Antonio Blanco",
          total_time: 12.5,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 12.5,
        },
        2569: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 24,
        },
        2570: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 4,
          regulartime: 24,
        },
        2571: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 5,
          regulartime: 24,
        },
        2572: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 6,
          regulartime: 24,
        },
        2573: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 4,
          regulartime: 24,
        },
        2574: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 2,
          regulartime: 24,
        },
        2575: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2576: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 24,
        },
        2577: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 4,
          regulartime: 24,
        },
        2578: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 5,
          regulartime: 24,
        },
        2579: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2580: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2581: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2582: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2583: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 2,
          regulartime: 24,
        },
        2584: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 24,
        },
        2585: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 2,
          regulartime: 24,
        },
        2586: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 4,
          regulartime: 24,
        },
        2587: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 6,
          regulartime: 24,
        },
        2588: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2589: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2590: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2591: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2592: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2593: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 3,
          regulartime: 24,
        },
        2594: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 2,
          regulartime: 24,
        },
        2595: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2596: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 1,
          regulartime: 24,
        },
        2597: {
          name_employee: "Cristian PA",
          total_time: 24,
          attendance_date: "2024-09-26T03:00:00.000Z",
          overtime: 0,
          regulartime: 24,
        },
      },
      hours: 481.5,
      hours_amount: 16852.5,
      hours_overtime: 215,
      hours_overtime_amount: 11287.5,
    },
    TOTAL: {
      grandTotal: 37383.75,
      totalAmount: 24015,
      totalOvertimeAmount: 13368.75,
    },
    invoice_number: "1",
  },
];
let blobsArray = [];

export const generatePDF = (
  project,
  index,
  invoiceType,
  projectTitles,
  range,
  facturaData,
  setProjects,
  blobsArray,
  setBlobsArray,
  rates
  
) => {
  // Crear un nuevo objeto jsPDF

  const pdf = new jsPDF("p", "mm", "a4", true);

  // Configuración del tamaño de texto y colores y ancho
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const sideMargin = 10;
  pdf.setFontSize(12);
  const orangeColor = [248, 112, 53];
  const whiteColor = [255, 255, 255];
  const blackColor = [0, 0, 0];
  const lighterOrange = [255, 224, 204];
  const darkenOrange = [255, 179, 128];
  // Agregar imagen (logo) en el lado izquierdo
  const logoImg = new Image();
  logoImg.src = "../assets/logo.png";
  const logoWidth = 30;
  const logoHeight = 14;

  pdf.addImage(logoImg, "PNG", sideMargin, 5, logoWidth, logoHeight); // Ajusta las coordenadas y el tamaño según sea necesario
  let currentPage = 1;

  // Lado derecho dividido en bloques
  // Bloque superior con la palabra "INVOICE"
  pdf.setTextColor(...orangeColor);
  pdf.setFontSize(15);
  //********************************************INVOICE TITlE****************************************************////
  const invoiceText = "INVOICE";
  const invoiceTextWidth =
    (pdf.getStringUnitWidth(invoiceText) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;
  const invoiceTextX = pageWidth - invoiceTextWidth - sideMargin;
  pdf.text(invoiceText, invoiceTextX, 10); // Ajusta las coordenadas según sea necesario
  // Bloque inferior con el table header
  const tableHeaderX = pageWidth / 2; // X inicial del table header
  const tableHeaderY = 13; // Y inicial del table header
  const cellWidth = pageWidth / 2 / 2 - sideMargin / 2;
  const cellHeight = 6;

  //***************************************************INVOICE #****************************************************////

  doHeader(
    pdf,
    "INVOICE #",
    25,
    orangeColor,
    whiteColor,
    tableHeaderX,
    tableHeaderY,
    cellWidth,
    cellHeight - 2
  );

  doHeader(
    pdf,
    "DATE",
    25,
    orangeColor,
    whiteColor,
    tableHeaderX + cellWidth,
    tableHeaderY,
    cellWidth,
    cellHeight - 2
  );
  const date = getDate();
  // Datos de la tabla
  const invoiceData = [
    { invoiceNumber: project.invoice_number.toString(), date },
  ];

  // Configuración de estilos para la data de la tabla
  pdf.setFillColor(...whiteColor);
  pdf.setTextColor(0, 0, 0); // Color de texto negro
  pdf.setFontSize(8);

  // Dibujar las celdas de la data de la tabla

  invoiceData.forEach((row, rowIndex) => {
    doData(
      pdf,
      row.invoiceNumber,
      9,
      rowIndex,
      whiteColor,
      blackColor,
      tableHeaderX,
      tableHeaderY,
      cellWidth,
      cellHeight - 2,
      true
    );
    doData(
      pdf,
      row.date,
      9,
      rowIndex,
      whiteColor,
      blackColor,
      tableHeaderX + cellWidth,
      tableHeaderY,
      cellWidth,
      cellHeight - 2,
      true
    );
  });
  //***************************************************BILL TO****************************************************////

  //params para doHeader (pdf, texto, margen de izq de texto, color fondo, color letra, coord X, coord Y, ancho, altura)

  doHeader(
    pdf,
    "BILL TO",
    15,
    orangeColor,
    whiteColor,
    sideMargin,
    logoHeight + 8,
    pageWidth / 2 - 20,
    cellHeight - 2
  );

  doHeader(
    pdf,
    "CUSTOMER ID",
    25,
    orangeColor,
    whiteColor,
    tableHeaderX,
    logoHeight + 8,
    cellWidth,
    cellHeight - 2
  );

  doHeader(
    pdf,
    "TERMS",
    25,
    orangeColor,
    whiteColor,
    tableHeaderX + cellWidth,
    logoHeight + 8,
    cellWidth,
    cellHeight - 2
  );

  ///
  const companyInfo = {
    name: "First Onsite",
    address_1: "3401 Quorum Drive, Suite 300",
    address_2: "Fort Worth, TX 76137",
    email: "Ap@firstonsite.com",
  }; // Agrega más filas según sea necesario
  const companyInfoArray = Object.values(companyInfo);
  const fontSize = 8;
  companyInfoArray.map((dato, index) => {
    pdf.setFillColor(...whiteColor);
    pdf.setTextColor(...blackColor); // Color de texto negro
    pdf.setFontSize(fontSize);
    const yPosition = logoHeight + cellHeight * 2.6 + index * 4;
    pdf.text(dato, sideMargin + 5, yPosition);
  });
  const customerData = { CustomerId: "MDW", terms: "Due Upon Receipt" };
  const customerDataArray = Object.values(customerData);
  customerDataArray.map((dato, index) => {
    doData(
      pdf,
      dato,
      8,
      0,
      whiteColor,
      blackColor,
      tableHeaderX + index * cellWidth,
      logoHeight + 5,
      cellWidth,
      cellHeight + 2,
      true
    );
  });

  //*********************************************DESCRIPTION HEADER************************************************////

  const qtyMargin = pageWidth / 2;
  const qtyWidth = pageWidth / 2 / 4;
  const unitPriceMargin = qtyMargin + qtyWidth;
  const unitPriceWidth = pageWidth / 2 / 3;
  //DESCRIPTION
  doHeader(
    pdf,
    "DESCRIPTION",
    25,
    orangeColor,
    whiteColor,
    sideMargin,
    pageHeight / 6.6,
    pageWidth / 2,
    cellHeight - 2
  );
  //QTY
  doHeader(
    pdf,
    "QTY",
    10,
    orangeColor,
    whiteColor,
    qtyMargin,
    pageHeight / 6.6,
    qtyWidth,
    cellHeight - 2,
    true
  );
  //UNIT PRICE
  doHeader(
    pdf,
    "UNIT PRICE",
    15,
    orangeColor,
    whiteColor,
    unitPriceMargin,
    pageHeight / 6.6,
    unitPriceWidth,
    cellHeight - 2,
    true
  );
  // AMOUNT
  doHeader(
    pdf,
    "AMOUNT",
    15,
    orangeColor,
    whiteColor,
    unitPriceMargin + unitPriceWidth,
    pageHeight / 6.6,
    unitPriceWidth,
    cellHeight - 2,
    true
  );
  const projectDataTitles = [
    "Project Name:",
    "Project Number:",
    `${invoiceType === "day" ? "Date:" : "Week:"}`,
  ];
  projectDataTitles.map((dato, index) => {
    pdf.setFillColor(...whiteColor);
    pdf.setTextColor(...blackColor); // Color de texto negr
    pdf.setFontSize(9);
    const yPosition =
      pageHeight / 6.6 + cellHeight * 1.5 + index * (cellHeight - 2);
    pdf.text(dato, sideMargin + 5, yPosition);
  });
  const projectTitleData = projectTitles[index].split("+");
  let projectDataArray;

  if (invoiceType === "week") {
    projectDataArray = [
      projectTitleData[0],
      projectTitleData[1],
      `${dateToMDA(range.date_start)} to ${dateToMDA(range.date_end)}`,
    ];
  } else {
    projectDataArray = [
      projectTitleData[0],
      projectTitleData[1],
      `${dateToMDA(range.date_start)}`,
    ];
  }

  projectDataArray.map((dato, index) => {
    pdf.setFillColor(...whiteColor);
    pdf.setTextColor(...blackColor); // Color de texto negro
    pdf.setFontSize(8);
    const yPosition =
      pageHeight / 6.6 + cellHeight * 1.5 + index * (cellHeight - 2);
    pdf.text(dato, pageWidth / 5, yPosition);
  });

  //*********************************************SKILLTECHNICIAN HOURS************************************************////

  //*********************************************SKILLTECHNICIAN DATA************************************************////
  let stDataArray,
    glDataArray,
    stOverTimeArray,
    glOverTimeArray = [];
  if (project.ST) {
    stDataArray = Object.values(project.ST.entries);
    stOverTimeArray = Object.entries(stDataArray)
      .filter(([key, value]) => value.overtime > 0)
      .map(([key, value]) => ({
        overtime: value.overtime,
        name_employee: value.name_employee,
      }));
  }
  if (project.GL) {
    glDataArray = Object.values(project.GL.entries);
    glOverTimeArray = Object.entries(glDataArray)
      .filter(([key, value]) => value.overtime > 0)
      .map(([key, value]) => ({
        overtime: value.overtime,
        name_employee: value.name_employee,
      }));
  }

  let stInitialYPosition = pageHeight / 6 + 26;
  let yPosition = stInitialYPosition;
  let printingItem = 1;
  if (project.ST && stDataArray?.length > 0) {
    drawRectangle(
      pdf,
      darkenOrange,
      sideMargin,
      pageHeight / 7.8 + 28,
      pageWidth - sideMargin * 2,
      cellHeight - 1
    );

    writeText(
      pdf,
      "Skill Technician hours:",
      10,
      blackColor,
      whiteColor,
      sideMargin + 5,
      pageHeight / 7.8 + 31.5
    );
    writeText(
      pdf,
      "Hours",
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 8,
      pageHeight / 7.8 + 31.5
    );
    writeText(
      pdf,
      project.ST.hours.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      qtyMargin + 15,
      pageHeight / 7.8 + 31.5
    );
    writeText(
      pdf,
      rates.st,
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 15,
      pageHeight / 7.8 + 31.5
    );
    writeText(
      pdf,
      "Subtotal : ",
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 28,
      pageHeight / 7.8 + 31.5
    );

    writeText(
      pdf,
      project.ST.hours_amount
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString(),
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + unitPriceWidth + 10,
      pageHeight / 7.8 + 31.5
    );
    stDataArray.map((employee) => {
      if (currentPage === 1 && printingItem > 34) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      } else if (printingItem > 57) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      }
      if (currentPage === 1) {
        yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
      } else {
        yPosition = 18 + (printingItem - 1) * cellHeight;
      }

      let regulartime = employee.regulartime.toFixed(2).toString();
      //  let hoursAmount = project.ST.hours_amount.toString();

      writeText(
        pdf,
        employee.name_employee,
        8,
        blackColor,
        whiteColor,
        pageWidth / 5,
        yPosition
      );
      writeText(
        pdf,
        regulartime,
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        yPosition,
        "right"
      );
      printingItem++;
    });

    // Totales de ST Standard
    yPosition = yPosition + 8; // para bajar el titulo siguiente
    drawRectangle(
      pdf,
      lighterOrange,
      sideMargin,
      yPosition - 5,
      pageWidth - sideMargin * 2,
      cellHeight - 1
    );
    writeText(
      pdf,
      "Total ST Hours",
      10,
      blackColor,
      whiteColor,
      pageWidth / 5,
      yPosition - 1.5
    );
    writeText(
      pdf,
      project.ST.hours.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 7 - 3,
      yPosition - 1.5,
      "right"
    );
    printingItem = printingItem + 1; // le sumo ese titulo
  }
  //*********************************************OVER TIME************************************************////
  let stOverTotalInitialPos = yPosition;

  if (project.ST && stOverTimeArray.length > 0) {
    let stOverInitialPos = yPosition + 5; //16 son dos celdas de alto
    drawRectangle(
      pdf,
      darkenOrange,
      sideMargin,
      stOverInitialPos - 5,
      pageWidth - sideMargin * 2,
      cellHeight
    );

    writeText(
      pdf,
      "Overtime ST",
      10,
      blackColor,
      whiteColor,
      pageWidth / 5.2,
      stOverInitialPos - 1
    );
    writeText(
      pdf,
      "Hours",
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 8,
      stOverInitialPos - 1
    );
    writeText(
      pdf,
      project.ST.hours_overtime.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      qtyMargin + 15,
      stOverInitialPos - 1
    );
    writeText(
      pdf,
      rates.st_ot,
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 15,
      stOverInitialPos - 1
    );
    writeText(
      pdf,
      "Subtotal : ",
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 28,
      stOverInitialPos - 1
    );
    writeText(
      pdf,
      project.ST.hours_overtime_amount
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString(),
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + unitPriceWidth + 10,
      stOverInitialPos - 1
    );

    printingItem = printingItem + 1; // le sumo ese anterior

    stOverTimeArray.map((employee) => {
      if (currentPage === 1 && printingItem > 41) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      } else if (printingItem > 45) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      }
      if (currentPage === 1) {
        yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
      } else {
        yPosition = 18 + (printingItem - 1) * cellHeight;
      }

      printingItem = printingItem + 1;
      writeText(
        pdf,
        employee.name_employee,
        8,
        blackColor,
        whiteColor,
        pageWidth / 5,
        yPosition
      );
      writeText(
        pdf,
        employee.overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        yPosition,
        "right"
      );
    });
    stOverTotalInitialPos = yPosition + 8;
    drawRectangle(
      pdf,
      lighterOrange,
      sideMargin,
      stOverTotalInitialPos - 5,
      pageWidth - sideMargin * 2,
      cellHeight
    );
    writeText(
      pdf,
      "Total OverTime ST",
      10,
      blackColor,
      whiteColor,
      pageWidth / 5,
      stOverTotalInitialPos - 1
    );

    writeText(
      pdf,
      project.ST.hours_overtime.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 7 - 3,
      stOverTotalInitialPos - 1,
      "right"
    );
    printingItem = printingItem + 1; // sumo el titulo anterior
    yPosition = stOverTotalInitialPos; // cambioaqui
  }
  //*********************************************GL HOURS************************************************/ ///
  let globalInitialPos, glTotalPosition;

  if (project.ST && stDataArray?.length > 0) {
    globalInitialPos = yPosition + 6;
  } else {
    globalInitialPos = yPosition;
  }
  if (project.GL && glDataArray?.length > 0) {
    drawRectangle(
      pdf,
      darkenOrange,
      sideMargin,
      globalInitialPos - 5,
      pageWidth - sideMargin * 2,
      cellHeight
    );

    writeText(
      pdf,
      "GL hours:",
      10,
      blackColor,
      whiteColor,
      sideMargin + 5,
      globalInitialPos - 1
    );
    writeText(
      pdf,
      "Hours",
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 8,
      globalInitialPos - 1
    );
    writeText(
      pdf,
      project.GL.hours.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      qtyMargin + 15,
      globalInitialPos - 1
    );
    writeText(
      pdf,
      rates.gl,
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 15,
      globalInitialPos - 1
    );
    writeText(
      pdf,
      "Subtotal : ",
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 28,
      globalInitialPos - 1
    );
    writeText(
      pdf,
      project.GL.hours_amount
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString(),
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + unitPriceWidth + 10,
      globalInitialPos - 1
    );

    printingItem = printingItem + 1; // sumo el titulo anterior

    glDataArray.map((employee) => {
      if (currentPage === 1 && printingItem > 35) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      } else if (printingItem > 40) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      }
      if (currentPage === 1) {
        yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
      } else {
        yPosition = 18 + (printingItem - 1) * cellHeight;
      }

      printingItem = printingItem + 1;

      writeText(
        pdf,
        employee.name_employee,
        8,
        blackColor,
        whiteColor,
        pageWidth / 5,
        yPosition
      );
      writeText(
        pdf,
        employee.regulartime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        yPosition,
        "right"
      );
    });
    glTotalPosition = yPosition + 8;
    drawRectangle(
      pdf,
      lighterOrange,
      sideMargin,
      glTotalPosition - 5,
      pageWidth - sideMargin * 2,
      cellHeight
    );

    writeText(
      pdf,
      "Total GL",
      10,
      blackColor,
      whiteColor,
      pageWidth / 5,
      glTotalPosition - 1
    );
    writeText(
      pdf,
      project.GL.hours.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 7 - 3,
      glTotalPosition - 1,
      "right"
    );
    printingItem = printingItem + 1; // sumo el titulo anterior
  }
  //*********************************************GL OT HOURS************************************************/ ///

  let glOverPosition = glTotalPosition + 5;
  if (project.GL && glOverTimeArray.length > 0) {
    drawRectangle(
      pdf,
      darkenOrange,
      sideMargin,
      glOverPosition - 4,
      pageWidth - sideMargin * 2,
      cellHeight
    );
    writeText(
      pdf,
      "GL Overtime",
      10,
      blackColor,
      whiteColor,
      sideMargin + 5,
      glOverPosition
    );
    writeText(
      pdf,
      "Hours",
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 8,
      glOverPosition
    );
    writeText(
      pdf,
      project.GL.hours_overtime.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      qtyMargin + 15,
      glOverPosition
    );
    writeText(
      pdf,
      rates.gl_ot,
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 15,
      glOverPosition
    );
    writeText(
      pdf,
      "Subtotal : ",
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + 28,
      glOverPosition
    );
    writeText(
      pdf,
      project.GL.hours_overtime_amount
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString(),
      10,
      blackColor,
      whiteColor,
      unitPriceMargin + unitPriceWidth + 10,
      glOverPosition
    );

    printingItem = printingItem + 1; // sumo el titulo anterior

    glOverTimeArray.map((employee) => {
      if (currentPage === 1 && printingItem > 35) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      } else if (printingItem > 45) {
        currentPage++;
        printingItem = 1;
        writeText(
          pdf,
          "Continue on next page",
          10,
          blackColor,
          whiteColor,
          sideMargin + 5,
          yPosition + 8
        );
        pdf.addPage();
      }
      if (currentPage === 1) {
        yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
      } else {
        yPosition = 18 + (printingItem - 1) * cellHeight;
      }

      printingItem = printingItem + 1;

      writeText(
        pdf,
        employee.name_employee,
        8,
        blackColor,
        whiteColor,
        pageWidth / 5,
        yPosition
      );
      writeText(
        pdf,
        employee.overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        yPosition,
        "right"
      );
    });
    let glOtTotalPosition = yPosition + 8;
    drawRectangle(
      pdf,
      lighterOrange,
      sideMargin,
      glOtTotalPosition - 5,
      pageWidth - sideMargin * 2,
      cellHeight
    );
    writeText(
      pdf,
      "Total OverTime GL",
      10,
      blackColor,
      whiteColor,
      pageWidth / 5,
      glOtTotalPosition
    );
    writeText(
      pdf,
      project.GL.hours_overtime.toFixed(2).toString(),
      10,
      blackColor,
      whiteColor,
      (3.3 * pageWidth) / 7 - 3,
      glOtTotalPosition,
      "right"
    );
    printingItem = printingItem + 1; // sumo el titulo anterior
    yPosition = glOtTotalPosition;
  }

  /////////////////********///////////////////////// */
  ///Evaluo si queda poco espacio para el pie de pagina
  if (currentPage === 1 && printingItem > 28) {
    writeText(
      pdf,
      "Continue on next page",
      10,
      blackColor,
      whiteColor,
      sideMargin + 5,
      yPosition + 8
    );
    pdf.addPage();
    currentPage++;

    yPosition = 0;
  }
  if (printingItem > 41) {
    writeText(
      pdf,
      "Continue on next page",
      10,
      blackColor,
      whiteColor,
      sideMargin + 5,
      yPosition + 8
    );
    pdf.addPage();
    currentPage++;
    yPosition = 18;
  } else {
    yPosition = yPosition + 8;
  }
  //////////////////****************************************************** */
  const footerStart = pageHeight - 60;

  //left SIDE

  pdf.setFont(undefined, "normal");

  let dataStart = footerStart + cellHeight * 1.5;

  footerData.map((line, index) => {
    writeText(
      pdf,
      line,
      8,
      blackColor,
      whiteColor,
      sideMargin,
      dataStart - 6 + index * 5
    );
  });

  //Right SIDE
  const rectangleWidth = (pageWidth - sideMargin * 2) / 4;
  const leftRectangleMargin = pageWidth / 2;
  const rightRectangleMargin = (3 * pageWidth) / 4 - sideMargin / 2;
  drawRectangle(
    pdf,
    darkenOrange,
    leftRectangleMargin,
    footerStart - 4,
    rectangleWidth,
    cellHeight * 5
  );
  drawRectangle(
    pdf,
    lighterOrange,
    rightRectangleMargin,
    footerStart - 4,
    rectangleWidth,
    cellHeight * 5
  );

  totalTitles.map((title, index) => {
    writeText(
      pdf,
      title,
      10,
      blackColor,
      darkenOrange,
      leftRectangleMargin + sideMargin,
      footerStart + index * cellHeight + 3
    );
  });

  pdf.setFillColor(...lighterOrange);
  pdf.setTextColor(...blackColor); // Color de texto negro
  pdf.setFontSize(10);
  const totalValues = [
    project.TOTAL.grandTotal
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .toString(),
    "-",
    "",
  ];
  totalValues.map((value, index) => {
    pdf.text(
      value,
      pageWidth - sideMargin * 2,
      footerStart + 2.5 + index * cellHeight,
      {
        align: "right",
      }
    );
  });

  pdf.setFillColor(...whiteColor);

  pdf.text(
    "If you have any questions about this invoice, please contact",
    pageWidth / 2,
    footerStart + 7 * cellHeight,
    {
      align: "center",
    }
  );

  pdf.setTextColor(...orangeColor);
  pdf.text(
    "admin@hcs-solution.com",
    pageWidth / 2,
    footerStart + 8 * cellHeight,
    {
      align: "center",
    }
  );

  pdf.setFont(undefined, "bold");

  writeText(
    pdf,
    "TOTAL",
    14,
    blackColor,
    darkenOrange,
    leftRectangleMargin + sideMargin,
    footerStart + 3 * 7.5 //3 porque es la longitud de titles array y 10 porque es el cell height de ahroa
  );
  pdf.setFontSize(14);

  pdf.text(
    `$ ${project.TOTAL.grandTotal
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .toString()}`,
    pageWidth - sideMargin * 2,
    footerStart + 3 * (cellHeight + 1),
    {
      align: "right",
    }
  );
  //
  pdf.setFont(undefined, "italic");
  writeText(
    pdf,
    "Thanks for your business!",
    10,
    orangeColor,
    whiteColor,
    pageWidth - 200,
    footerStart + 8 * cellHeight + 6
  );

  const pagesQty = pdf.internal.getNumberOfPages();
  pdf.setFontSize(8);
  for (let i = 1; i <= pagesQty; i++) {
    pdf.setPage(i);
    pdf.text(`${i} of ${pagesQty}`, pageWidth / 2, pageHeight - 5, {
      align: "center",
    });
  }
  // Guardar el PDF localmente
/* 
  pdf.save(
    `${projectTitleData[1]}-Invoice-${
      invoiceData[0].invoiceNumber
    }-from-${dateToMDA(range.date_start)}-to-${dateToMDA(range.date_end)}.pdf`
  ); */



  // Blob para email
  const blob = pdf.output("blob");

  /* const blobArray = emailData.data || [];
  console.log(blobArray);
  const newData = [...blobArray];
  console.log('new Data spreaded' , newData);
  newData[index] = { 
    invoice: blob,
    project: projectTitleData[0],
    total: project.TOTAL.grandTotal,
    start_date: `${dateToMDA(range.date_start)}`,
    end_date:`${dateToMDA(range.date_end)}`,
   }
   console.log('new Data por index' , newData[index]);

  emailDataDispatch({ type: "UPDATE_BLOB", payload: newData });

  console.log( newData); */

  setBlobsArray((prevData) => {
    const newData = [...prevData];
    newData[index] = {
      invoice: blob,
      invoice_number: invoiceData[0].invoiceNumber,
      project: projectTitleData[0],
      project_number: projectTitleData[1],
      total: project.TOTAL.grandTotal,
      start_date: `${dateToMDA(range.date_start)}`,
      end_date: `${dateToMDA(range.date_end)}`,
    };
    return newData;
  });

  // Crea una URL para el blob
  /*   const projects = Object.values(facturaData.data)[0].projects;
  const projectsLength = Object.values(projects).length;


  const url = URL.createObjectURL(blob); */

  // const windowName = `Project_${projectTitleData[1]}`;
  // Abre una nueva ventana y muestra la vista previa del PDF
  // window.open(url, "_blank").name = "NombrePersonalizado";
  setProjects([]);
  

  // window.open(url, windowName, ["_blank"]);
};
